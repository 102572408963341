<script lang="ts" setup>
withDefaults(
	defineProps<{
		enabledPopup?: boolean;
	}>(),
	{
		enabledPopup: true
	}
);

const { isOpen } = useAppModals();
const {
	unreadNotifications,
	readNotifications,
	isActiveAnimation,
	showNotificationPopup,
	handleClickNotificationMessage,
	setNotificationsStatusAsRead,
	updateNotificationsStatusAsRead,
	openPopup
} = useNotificationCenter({
	isOpen
});
</script>
<template>
	<div
		class="notification-center"
		data-tid="notification-center"
		:class="{ active: isActiveAnimation }"
		@click="openPopup"
	>
		<NuxtIcon class="icon" name="24/notification-mail" filled />
		<AText
			v-if="unreadNotifications?.length"
			as="div"
			variant="tallinn"
			:modifiers="['bold']"
			class="unread-count text-coro"
		>
			{{ unreadNotifications?.length }}
		</AText>
		<ONotificationCenterPopup
			v-if="showNotificationPopup && enabledPopup"
			:unread-notifications="unreadNotifications"
			:read-notifications="readNotifications"
			@set-status-read="setNotificationsStatusAsRead"
			@click-on-message="handleClickNotificationMessage"
			@update-notification-status="updateNotificationsStatusAsRead"
			@close-popup="showNotificationPopup = false"
		/>
	</div>
</template>
<style scoped lang="scss">
.notification-center {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	.icon {
		color: var(--cannes);
	}

	&.active {
		.icon {
			animation: mail-rotate 4s infinite;
		}
	}

	&:deep(.popper) {
		background-color: var(--carabanchel);
		border: 2px solid var(--chitungwiza);
		z-index: 10;
		border-radius: 16px;
		padding: 0;
		transform: none !important;
		left: auto !important;
		right: -180px !important;
		top: calc(100% + 15px) !important;
		overflow: auto;

		&:hover {
			background-color: var(--carabanchel);
		}

		@include media-breakpoint-down(lg) {
			border: none;
			border-radius: 0;
			position: fixed !important;
			left: 0 !important;
			top: 0 !important;
			right: auto !important;
			width: 100%;
			max-width: 100%;
			height: 100dvh;

			@media (orientation: landscape) {
				top: 0 !important;
				height: 100dvh;
			}
		}
	}
}

.icon {
	font-size: 20px;

	&:deep(svg) {
		margin-bottom: 0;
	}
}

.unread-count {
	position: absolute;
	top: -2px;
	right: -6px;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	background-color: var(--coyoacan);
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

@keyframes mail-rotate {
	0% {
		transform: rotate(0deg);
	}
	80% {
		transform: rotate(0deg);
	}
	85% {
		transform: rotate(-10deg);
	}
	95% {
		transform: rotate(10deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
</style>
